@import '~bootstrap/scss/_functions';
@import '../../theme//functions';

@import '~bootstrap/scss/mixins';
@import '../../theme/mixins';

@import '../../user-variables';
@import '../../theme/colors';
@import '../../theme/variables';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';

.return-to-overview {
  color: #344050;
  cursor: pointer;
  font-size: 1rem;
}

.return-to-overview:hover {
  text-decoration: underline;
}

//-------------------------------
.client-details-card {
  position: relative;
}

.client-details-card-body {
  padding: 0 !important;
}

.client-details-card-header {
  height: 20%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.3rem 1rem;
  padding-left: 2rem;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background-color: #F9FAFD;
}

@if $enable-dark-mode {
  @include color-mode(dark, true) {
    .client-details-card-header {
      background-color: #344050;
    }
  }
}

.header-color-for-client {
  background-color: #a8e2c9;
}

.header-color-for-prospect {
  background-color: #d9e7fa;
}

.edit-button {
  margin-left: auto;
}

.client-industry-icon-div {
  position: relative;
  width: 10%;
  height: 10%;
  margin-left: 2rem;
  top: -3.5rem;
}

.client-industry-icon-div .client-industry-icon {
  width: 100%;
  height: 100%;
  z-index: 1;
}

.client-details-card-content {
  padding: 1rem 2rem;
}

.since-text-style {
  font-weight: 300;
  color: #9da9bb;
  font-size: 0.8333333333rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
  line-height: 1.2;
}

.info-card-basic-info {
  padding: 0rem 1rem;
  margin: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
}

.org-info-card p {
  margin: 0;
  padding-left: 1.5rem;
}

.edit-general-info-button {
  display: flex;
}

.info-card-basic-column {
  flex: 1 1;
  box-sizing: border-box;
  padding: 0 1rem;
  margin-bottom: 2rem;
}

.info-card-basic-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  /* Two equal columns */
  grid-template-rows: repeat(1, 1fr);
  /* Two equal rows */
  gap: 0rem 0rem;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.info-card-basic-grid .grid-item {
  padding: 0;
  margin-bottom: 0;
  font-size: 14px;
  height: 30px;
  min-width: 14vw;
}

.grid-item {
  padding: 0;
  margin: 0;
}

.basic-info-contact-header {
  font-weight: bold;
}

.primary-contact-info-container {
  display: flex;
  align-items: center;
  /* Align items vertically center */
}

.primary-contact-info-details {
  display: flex;
  flex-direction: column;
}

.primary-contact-info-item {
  display: flex;
  flex-direction: column;
}

.primary-contact-icon {
  margin-right: 1rem;
}

.convert-prospect-card {
  justify-content: center;
}

.add-blur {
  filter: blur(10px) !important;
}

.client-details-grid-parent {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: repeat(9, 1fr);
  grid-column-gap: 0.5rem;
  grid-row-gap: 0rem;
}

.item1 {
  grid-area: 1 / 7 / 10 / 10;
}

.item2 {
  grid-area: 1 / 1 / 3 / 3;
}

.item3 {
  grid-area: 3 / 1 / 5 / 3;
}

.item4 {
  grid-area: 1 / 3 / 5 / 7;
  margin-bottom: 0.5rem;
}

.item5 {
  grid-area: 5 / 1 / 10 / 7;
}

@media (max-width: 768px) {
  .info-card-basic-grid {
    display: block;
    gap: 0;
  }

  .grid-item {
    min-width: 75%;
    font-size: 0.85rem;
    padding: 1rem;
    box-sizing: border-box;
    display: block;
    margin-bottom: 0.5rem;
  }

  .primary-contact-info-container {
    display: block;
    gap: 0;
  }

  .primary-contact-info-details {
    display: block;
    gap: 0;
  }

  .primary-contact-info-item {
    display: block;
    gap: 0;
  }

  .client-details-grid-parent {
    display: block;
    gap: 0;
  }

  .edit-general-info-button {
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 2rem;
    display: block;
  }
}
