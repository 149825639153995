@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Great+Vibes&display=swap');

.certificate-background {
    background-image: url('../../../img/illustrations/certificate-frame.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.certificate-container {
    display: flex;
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
    flex-direction: column; /* Ensure elements stack vertically */
    min-height: 100vh; /* Full viewport height */
    text-align: center; /* Center text within the container */
    padding: 20px;
}

.certificate-top-text {
    margin: 0;
    padding: 0;
    font-size: 7rem;
    color: white;
}

.certificate-second-text {
    margin: 0;
    padding: 0;
    font-size: 2rem;
    color: white;
}

.certificate-third-text {
    padding-top: 2rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 1rem;
    color: white;
}

.certificate-user-text {
    padding-top: 1rem;
    font-family: 'Great Vibes', cursive;
    font-size: 5rem;
    color: white;
}

.certificate-course-text {
    padding-top: 1rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: lighter;
    font-size: 1.5rem;
    color: white;
}