/* Container Styling */
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

/* Card Styling */
.feedback-form {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.feedback-form .card-header {
  background: #d8e2ef;
  padding: 15px;
}

.feedback-form .card-body {
  padding: 20px;
}

/* Form Elements Styling */
.feedback-form form {
  display: flex;
  flex-direction: column;
}

.feedback-form form ol {
  list-style: none;
  padding: 0;
}

.feedback-form form li {
  margin-bottom: 20px;
}

.feedback-form form h5 {
  margin-bottom: 10px;
}

.feedback-form form p {
  margin-bottom: 10px;
}

.feedback-form form label {
  display: block;
  margin-bottom: 5px;
}

.feedback-form form .radio-score {
  display: inline-block;
}

.feedback-form form input[type='radio'] {
  margin-right: 10px;
}

.feedback-form form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

/* Button Styling */
.feedback-form .btn-sm {
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 4px;
  transition: box-shadow 0.3s ease;
}

.feedback-form .btn-sm:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

/* Message Styling */
.feedback-form .text-center {
  text-align: center;
}

.feedback-form .text-center p {
  margin-top: 10px;
  color: #00d27a;
}

/* Responsive Design */
@media (max-width: 768px) {
  .feedback-form {
    padding: 10px;
  }

  .feedback-form .card-body {
    padding: 15px;
  }

  .feedback-form form li {
    margin-bottom: 15px;
  }

  .feedback-form form textarea {
    padding: 8px;
  }

  .feedback-form .btn-sm {
    padding: 8px 16px;
    font-size: 12px;
  }
}
