.price-per-client-plus {
  color: rgb(var(--falcon-primary-rgb));
  font-size: 30px;
  font-weight: 600;
  letter-spacing: -0.05rem;
}

.price-client-label {
  margin-right: 1rem;
}

.price-month {
  color: rgb(var(--falcon-dark-rgb));
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.05rem;
}

.price-month-label {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.05rem;
}

.plan-description {
  margin-top: 1rem;
}
