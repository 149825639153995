.client-intake {
  color: var(--Gray-Shades-Colors-Gray-900, #344050);
  font-family: Poppins;
  font-style: normal;
}

.client-intake .theme-wizard {
  max-height: 46rem;
  max-height: calc(85vh - var(--falcon-top-nav-height));
}

.client-intake .intake-step {
  overflow-y: auto;
  overflow-x: hidden;
}

.client-intake h4 {
  font-size: 24px;
  font-weight: 700;
  line-height: 156%;
}

.client-intake .subheader {
  font-size: 16px;
  font-weight: 300;
  line-height: 156%;
}

.client-intake .accordion-header .header {
  flex: 1 3 25%;
  padding-right: 0.5rem;
  margin-bottom: 0;
}

.client-intake .accordion-header .subheader {
  flex: 3 1 75%;
  margin-bottom: 0;
}

.client-intake label.form-label {
  font-size: 13px;
  font-weight: 500;
  line-height: 120%; /* 15.6px */
}

.client-intake label.form-label:has(> p.sublabel) {
  font-size: 15px;
  font-weight: 500 !important;
  line-height: normal;
}

.client-intake label.form-label p.sublabel {
  font-size: 12px;
  font-weight: 300 !important;
  line-height: normal;
  white-space: nowrap;
  margin-bottom: 0;
}

.client-intake .sublabel-wrap label.form-label p.sublabel {
  white-space: normal;
}

.client-intake .records-estimate label.form-label {
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
}

.client-intake .records-estimate input {
  text-align: center;
  display: inline-block;
}

@media (min-width: 768px) {
  .client-intake .md-num-lines-3 label.form-label p.sublabel {
    min-height: 4.5em;
    min-height: 3lh;
  }

  .client-intake .records-estimate input {
    max-width: 60%;
  }
}

/*
.client-intake input,
.client-intake select {
  font-size: 13px;
  font-weight: 400;
  line-height: 120%;
}
*/
