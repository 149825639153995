.faq-header{
    color: #2C7BE5;
    font-size: 14px;
  }
.sub-header{
    font-size: 14px;
}

.page-text{
  font-size: 14px;
}

.force-margin {
  margin-top: 1rem !important;
}
  
 
  