// CHANGE URL TO PROPER FORMAT
.bg-corner-1-card {
  background-image: url('../../../img/illustrations/corner-1.png');
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-corner-2-card {
  background-image: url('../../../img/illustrations/corner-2.png');
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-corner-4-card {
  background-image: url('../../../img/illustrations/corner-4.png');
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
}