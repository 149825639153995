.user-settings-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.user-list {
  list-style-type: none;
  padding: 0;
}

.user-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
  padding: 10px;
}

.custom-dropdown-toggle::after {
  display: none !important;
}

.icon-square-box {
  display: inline-block;
  width: 17px;
  height: 17px;
  background-color: #3b3b3b;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
}
.icon-square-box.active {
  background-color: blue;
}
