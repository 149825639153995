.integrations-container {
    width: 100%;
    height: 75vh;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 1rem; 
    padding: 1rem; 
}
  
.integration-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 1rem; 
}

@media (max-width: 768px) {
    .integrations-container {
        grid-template-columns: 1fr;
    }
}