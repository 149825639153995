.welcome-card {
    min-height: 40vh;
}

.grid-container {
    display: flex;
    flex-wrap: wrap;
}

.grid-item {
    width: 45%;
    /* Two items per row */
    padding: 10px;
    margin-bottom: 2.5%;
    margin-right: 5%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.grid-item:hover {
    cursor: pointer !important;
}

.item-icon {
    width: 15%;
    /* Adjust width as needed */
    height: auto;
    /* Maintain aspect ratio */
    margin-right: 10px;
    /* Space between icon and text */
}

.item-text {
    flex: 1;
    /* Fill remaining space */
}

.item-text:hover {
    text-decoration: underline;
}

.item-text h4 {
    margin: 0;
    /* Remove default margin */
}

.item-text p {
    margin: 0;
    /* Remove default margin */
    font-size: 0.85rem;
}

.inline-logo {
    width: 24px;
    height: 24px;
    margin-right: 0.5rem;
}

.content-section {
    margin-bottom: 1rem;
}

.section-header {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
}

.section-text {
    display: flex;
    align-items: center;
    margin: 0;
}

.custom-list {
    list-style: none;
    /* Remove default bullet points */
    padding: 0;
    /* Remove default padding */
    margin: 5% 0px;
}

.custom-list li {
    display: flex;
    align-items: center;
    margin: 5% 0px;
}

.custom-list li .fa-icon {
    margin-right: 10px;
    /* Space between icon and text */
}

.get-started-section-container {
    margin: 5% 0px;
}

@media (max-width: 430px) {
    .grid-item {
        width: 100%;
        /* Two items per row on larger screens */
    }

    .item-icon {
        width: 3rem;
        /* Adjust width as needed */
        height: auto;
        /* Maintain aspect ratio */
        margin-right: 10px;
        /* Space between icon and text */
    }

    .item-text p {
        margin: 0;
        /* Remove default margin */
        font-size: .8rem;
    }

    .item-text h5 {
        margin: 0;
        /* Remove default margin */
        font-size: 1rem;
    }
}