@import '~bootstrap/scss/_functions';
@import '../../theme//functions';

@import '~bootstrap/scss/mixins';
@import '../../theme/mixins';

@import '../../user-variables';
@import '../../theme/colors';
@import '../../theme/variables';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';

.policy-radio-label {
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
  }
  
  .policy-radio-input {
    margin-right: 0.25rem;
  }
  
  .policy-span-start, .policy-span-end {
    font-weight: bold;
  }

@if $enable-dark-mode {
  @include color-mode(dark, true) {
    .dropdownColor {
      color: white;
    }

    .dropdown-title {
      color: white !important;
    }
  }
}