// _datepickerOverrides.scss

// Style the calendar container
.react-datepicker {
    font-family: Arial, sans-serif;
  
    &__header {
      background-color: black;
      border-bottom: 1px solid black;
    }
  
    &__day {
      color: black;
    }
  
    &__day--selected {
      background-color: black;
      color: black;
    }
  
    &__day--today {
      border: 2px solid black;
    }
  
    &__triangle {
      border-top-color: black;
    }
  }
  
  // Customize the custom input component
  .custom-datepicker {
    padding: 10px;
    border: 1px solid black;
    border-radius: 4px;
    background-color: black;
  
    &:focus {
      border-color: black;
      outline: none;
    }
  }
  