/* Loader.css */

@import '~bootstrap/scss/_functions';
@import '../../theme//functions';

@import '~bootstrap/scss/mixins';
@import '../../theme/mixins';

@import '../../user-variables';
@import '../../theme/colors';
@import '../../theme/variables';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';


.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
  
.loader-card {
  width: 40%;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.logo-container {
  margin: 1rem;
  padding: 1rem;
}
  
.loader-icon {
  width: 25%;
  height: 25%;
}

.loader-rotation {
  animation: rotation 2s infinite linear;
  //animation: loading 2s infinite linear;
}

@keyframes loading {
  0% {
      width: 0%;
  }
  100% {
      width: 100%;
  }
}

@keyframes rotation {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(359deg);
  }
}

@if $enable-dark-mode {
  @include color-mode(dark, true) {
    
    .loader-card {
      background-color: #142435;
    }

  }
}
  