.account-settings-card-body,
.security-settings-card-body {
  margin-top: 1rem;
  border-top: 1px solid #f0f0f0;
}

.account-settings-navbar-active {
  font-weight: bolder;
}

.security-settings h6 {
  margin: 1rem 0;
  font-weight: bold;
}

.general-settings-top-section {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
  padding-bottom: 2rem;
  border-bottom: 3px solid #f0f0f0;
}

.account-general-details-form {
  margin-top: 2rem;
  margin-bottom: 1rem;
}
