/* For list view - cards stack vertically */
.list-view {
    display: block;
  }
  
  /* For grid view - cards are displayed in a two-column layout */
  .grid-view {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem; /* Space between grid items */
  }
  