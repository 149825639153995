body,
html {
  height: 100%;
  background-color: #edf2f9;
}

label {
  font-weight: 500;
}

main {
  height: 100%;
}

.login-container {
  position: relative;
  height: 100%;
  display: flex;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
}

.login-container label {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  margin-bottom: 5px;
}

.login-box {
  align-self: center;
  padding: 0;
  background-color: #fff;
  box-shadow: 0px 5px 5px #ccc;
  border-radius: 5px;
  border-top: 1px solid #e9e9e9;
  display: flex;
  margin: auto;
  max-width: 800px;
  width: 90%;
  position: relative;
}
.left-login-col {
  background: linear-gradient(158.02deg, #4695ff 0%, #1970e2 99.47%);
  width: 40%;
  position: relative;
  overflow: hidden;
}
.left-login-col p {
  font-size: 14px;
  width: 80%;
  margin: auto;
}
.right-login-col {
  padding: 40px;
  width: 60%;
  position: relative;
  z-index: 10;
  background: #fff;
}

.login-message-inner {
  text-align: center;
  color: #fff;
  padding: 15px;
}

.login-header img {
  width: 75px;
}
#bottomLeftText {
  padding: 70px 0 15px;
}
#bottomLeftText a {
  color: #fff;
  text-decoration: underline;
}

#error-message {
  display: none;
  white-space: break-spaces;
}
#auth-logo {
  width: 175px;
}
#background-overlay {
  width: 100%;
  position: absolute;
  top: -100px;
  left: 0;
}
#tos {
  padding-top: 30px;
  font-size: 12px;
}
.graphic-one {
  position: absolute;
  bottom: -60px;
  left: -100px;
}
.graphic-two {
  position: absolute;
  right: -150px;
  top: -100px;
}
